import React from "react";
import {SignUp as SingUpBase} from "aws-amplify-react";
import {I18n} from 'aws-amplify'
import PhoneField from "aws-amplify-react/dist/aws-amplify-react";

export class SignUp extends SingUpBase {

  showComponent(theme) {

    this.signUpFields = this.props.signUpConfig.signUpFields;


    const SubmitForm = (e) => {

      let pwd = document.getElementsByName("password")[0].value;
      let pwd_confirm = document.getElementsByName("confirm_password")[0].value;

      let el = document.getElementById('flashDiv');
      if (el) {
        el.remove();
      }

      if (pwd === pwd_confirm) {
        super.signUp()
      } else {
        let flashDiv = document.createElement('div');
        flashDiv.id = 'flashDiv';
        document.getElementsByTagName('body')[0].appendChild(flashDiv);
        flashDiv.innerHTML = "Senhas estão diferentes";
      }

    };

    const Field = ({field, ...props}) => {

      let columns = "";

      if (field.key === "name") {
        columns = "col-sm-12";
      } else {
        columns = "col-sm-6";
      }

      return (
        <div className={`col ${columns}`}>

          {field.key !== 'phone_number' ? (
            <input
              autoFocus={
                this.signUpFields.findIndex((f) => {
                  return f.key === field.key
                }) === 0
              }
              placeholder={`${I18n.get(field.label)}${!field.required ? ' (opcional)' : ''}`}
              type={field.type}
              name={field.key}
              key={field.key}
              onChange={this.handleInputChange}
              className="input"
            />
          ) : (
            <PhoneField
              required={field.required}
              defaultDialCode={this.getDefaultDialCode()}
              className="input"
              label={field.label}
              placeholder={`${I18n.get(field.label)}${!field.required ? ' (opcional)' : ''}`}
              onChangeText={this.onPhoneNumberChanged}
              key="phone_number"
            />
          )}

        </div>
      );
    };

    return (
      <div className="row form-signUp d-flex justify-content-center">
        <div className="col col-12 col-sm-8">
          <div className="text-center">
            <div className="current-steps">
              <ul className="list-unstyled">
                <li className="active">1</li>
                <li>2</li>
              </ul>
            </div>
          </div>

          <div className="text-center mb-30 subtitlecontainer">
            {/* <div className="title">Cadastre agosta e ganhe <span>10 dias grátis,</span> cancele quando quiser ou adquira
              sua(s) <span>trilhas(s).</span>
            </div> */}
          </div>

          <form>
            <div className="row">
              {this.signUpFields.map((field, index) => (
                <Field key={index} field={field}/>
              ))}

              <div className="col col-sm-6">
                <input
                  placeholder={`${I18n.get('Confirm Password')}`}
                  type="password"
                  name="confirm_password"
                  key="confirm_password"
                  className="input"
                />
              </div>
              <div className="col-12 d-flex justify-content-center">
                <div className="col-6">
                  <button className="text-center btn" type="button" onClick={() => SubmitForm()}>
                    {I18n.get('Continue')}
                  </button>
                  {/*<button className="btn" type="button" onClick={() => super.signUp()}>
                    {I18n.get('Continue')}
                  </button>*/}

                  <div className="text-center"><a className="back-to-sign-in" onClick={() => super.changeState("signIn")}>
                    {I18n.get('Back to Sign In')}
                  </a></div>
                </div>
              </div>

            </div>

          </form>

        </div>
      </div>
    )

    return (
      <div className="row">
        <div className="col col-12 col-sm-8 offset-sm-2">
          <div className="text-center logo">
            <a href={`https:/${process.env.REACT_APP_DOMAIN}`}>
              <img src="/assets/images/logo_v2.png" alt="Athié Wohnrath" className="img-fluid logo"/>
            </a>
          </div>

          <div className="text-center">
            <div className="current-steps">
              <ul className="list-unstyled">
                <li className="active">1</li>
                <li>2</li>
              </ul>
            </div>
          </div>

          <div className="text-center mb-30 subtitlecontainer">
            <div className="title">Cadastre agosta e ganhe <span>10 dias grátis</span>, cancele quando quiser ou adquira
              sua(s) <span>trilhas(s)</span>.
            </div>
          </div>

          <form>

            <input
              className="input"
              id="name"
              key="name"

              name="name"
              type="text"
              autoFocus
              onChange={this.handleInputChange}
              placeholder={I18n.get('Full Name')}
            />

            <div className="row">
              <div className="col col-12 col-sm-6">
                <div className="has-float-label">
                  <input
                    className="input"
                    id="email"
                    key="email"
                    name="email"
                    type="email"
                    onChange={this.handleInputChange}
                    placeholder={I18n.get('Email')}
                  />
                  <label htmlFor="email">Digite aqui seu <b>Nome</b></label>
                </div>  
              </div>

              <div className="col col-12 col-sm-12">

                <div className="d-none">
                  <select name="dial_code">
                    <option value="+55" selected="">+55</option>
                  </select>
                </div>

                <input
                  className="input"
                  id="phone_line_number"
                  key="phone_line_number"
                  name="phone_line_number"
                  type="text"
                  onChange={this.handleInputChange}
                  placeholder={I18n.get('Phone Number (optional)')}
                />
              </div>

              <div className="col col-12 col-sm-12">
                <input
                  className="input"
                  id="password"
                  key="password"
                  name="password"
                  type="password"
                  onChange={this.handleInputChange}
                  placeholder={I18n.get('Password')}
                />
              </div>

              <div className="col col-12 col-sm-12">
                <input
                  className="input"
                  id="passwordConfirm"
                  key="passwordConfirm"
                  name="passwordConfirm"
                  type="password"
                  onChange={this.handleInputChange}
                  placeholder={I18n.get('passwordConfirm')}
                />
              </div>
            </div>

            <button className="btn btn-light" type="button" onClick={() => super.signUp()}>
              {I18n.get('Continue')}
            </button>

            <div className="text-right">
              <a className="back-to-sign-in" onClick={() => super.changeState("signIn")}>
                {I18n.get('Back to Sign In')}
              </a>
            </div>

          </form>

        </div>
      </div>
    );
  }
}
