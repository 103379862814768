import React from 'react';
import { I18n } from 'aws-amplify';
import { ForgotPassword } from 'aws-amplify-react';

export class CustomForgotPassword extends ForgotPassword {

  showComponent() {

    const { authData = {} } = this.props;

    return (
      <div className="cardMiddle">
        <div>
          <div>
            <h1 className="text-center">Criar uma nova senha</h1>

            <div className="">
              <div className="">
                <div className="ResetPassword text-left">

                  {this.state.delivery || authData.username ?
                    <>
                      <input
                        className="input"
                        id="code"
                        key="code"
                        name="code"
                        type="text"
                        autoComplete="off"
                        onChange={this.handleInputChange}
                        placeholder={I18n.get("Code")}
                      />
                      <input
                        className="input"
                        id="password"
                        key="password"
                        name="password"
                        type="password"
                        onChange={this.handleInputChange}
                        placeholder={I18n.get("New Password")}
                      />
                    </> 
                  :
                    <input
                      className="input"
                      id="username"
                      key="username"
                      name="username"
                      type="text"
                      autoComplete="off"
                      onChange={this.handleInputChange}
                      placeholder={I18n.get("Enter your email")}
                    /> 
                  }

                  {this.state.delivery || authData.username ?
                    <div className="text-center">
                      <button className="btn" onClick={this.submit}>{I18n.get('Submit')}
                      </button>
                    </div> :
                    <div className="text-center">
                      <button className="btn" onClick={this.send}>{I18n.get('Send Code')}
                      </button>
                    </div>
                  }
                  {this.state.delivery || authData.username ?
                    <div className="text-center">
                      <a className="back-to-sign-in" onClick={this.send}>
                        {I18n.get('Resend Code')}
                      </a>
                    </div> :
                    <div className="text-center">
                      <a className="back-to-sign-in mb-30"
                        onClick={() => super.changeState("signIn")}>
                        {I18n.get('Back to Sign In')}
                      </a>
                    </div>
                  }
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    );
  }


};
