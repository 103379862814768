/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:5b5538a2-b2d3-4cae-9b1d-54b6af9785b2",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_o03mP1K5N",
    "aws_user_pools_web_client_id": "1i0lqpa3ur9uqp1ebh9rm82se4",
    "oauth": {}
};


export default awsmobile;
