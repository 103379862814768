export const signUpConfig = {
  header: "Create a new account",
  hideAllDefaults: true,
  defaultCountryCode: "55",
  signUpFields: [
    {
      label: "Full Name",
      key: "name",
      required: true,
      displayOrder: 1,
      type: "string"
    },
    {
      label: "CPF",
      key: "username",
      required: true,
      displayOrder: 2,
      type: "string"
    },
    {
      label: "Email",
      key: "email",
      required: true,
      displayOrder: 3,
      type: "string"
    },
    {
      label: "Password",
      key: "password",
      required: true,
      displayOrder: 4,
      type: "password"
    }
  ]
};

export const usernameAttributes = "username";
