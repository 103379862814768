import React from 'react';
import config from '../../aws-exports';
import {
  Authenticator,
  ConfirmSignIn,
  ConfirmSignUp,
  ForgotPassword,
  RequireNewPassword,
  SignIn,
  SignUp,
  VerifyContact
} from 'aws-amplify-react';
import { academyLoginTheme, adminLoginTheme } from '../../theme';
import { signUpConfig, usernameAttributes } from '../../configs/signUpConfig';
import { SignUp as CustomSignUp } from '../custom-pages/SignUp';
import { CustomForgotPassword } from '../custom-pages/CustomForgotPassword';
import { CustomSignIn } from '../custom-pages/CustomSignIn';
import { getRedirectUrl, hasAdmin, createCookie, clearCookie, redirectTo } from '../../util/util';
import { BoxLogin } from './styles';
import { Auth } from 'aws-amplify';
import { FadeIn, FadeInSlow } from '../../style';

export default class Authentication extends React.Component {
  constructor() {
    super();
    this.state = {
      authState: '',
      page: window.location.href.indexOf('signUp') > -1 ? 'signUp' : ''
    };
  }

  redirectToState = () => { 
    switch (this.props.page) {
      case 'renew' :
        return 'forgotPassword';
      case 'signUp' :
        return 'signUp';
      default:
        return 'signIn'
    }
  };

  async isAuth() {
    try {
      const response = await Auth.currentSession();
      createCookie(response);

      this.setState({
        authState: 'signedIn'
      })
    }
    catch (e) {
      clearCookie();
      this.setState({
        authState: 'signOut'
      })
    }
  }

  onStateChange = async (authState) => {
    this.setState({ authState });
    await this.isAuth();

    if (authState === 'signedIn') {
      getRedirectUrl()
    }
  };

  render() {
    const { authState, page } = this.props;
    return (

      <div className={`bg_component${hasAdmin() ? ' has-admin' : ''}`}>
        <FadeIn>
          <div className="content-login">
            <div className="container-image"/>
            <div className="container-form">
              <div className="base-plan">
                <div className="container">

                  <BoxLogin className="col-md-8 my-5 col-lg-4">
                    {authState !== 'signedIn' ? (
                      <div className={`${this.state.authState || ''} text-center logo`}>
                        <h4 className={`subtitle-login ${window.location.href.indexOf('signUp') > -1 ? 'signUp' : ''}`}>
                          {hasAdmin() ? 'Administração': ''}
                        </h4>
                      </div>
                    ) : (
                      <div className="force-align-center">
                        <div className="spinner"/>
                      </div>
                    )}
                    <Authenticator
                      authState={this.redirectToState()}
                      amplifyConfig={config}
                      onStateChange={this.onStateChange}
                      usernameAttributes={usernameAttributes}
                      theme={hasAdmin() ? adminLoginTheme : academyLoginTheme}
                      hide={[
                        SignIn,
                        ConfirmSignIn,
                        RequireNewPassword,
                        SignUp,
                        ConfirmSignUp,
                        VerifyContact,
                        ForgotPassword,
                      ]}
                      hideDefault={true}
                    >
                    
                      <CustomSignIn override="SignIn"/>
                      <ConfirmSignIn/>
                      <RequireNewPassword/>
                      <CustomSignUp override="SignUp" signUpConfig={signUpConfig}/>
                      <ConfirmSignUp/>
                      <VerifyContact/>
                      <CustomForgotPassword override='ForgotPassword'/>
                    </Authenticator>
                  </BoxLogin>
                </div>

              </div>
            </div>
          </div>
        </FadeIn>
      </div>
    )
  }
}
