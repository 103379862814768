import React from "react";
import { SignIn } from "aws-amplify-react";
import { Auth, I18n } from "aws-amplify";
import InputMask from 'react-input-mask';
import logoMaisSaber from '../../assets/images/logo_mais_saber.png';
import { settings } from '../../configs/settings';
import logoSoma from '../../assets/images/logo_v2.png';

export class CustomSignIn extends SignIn {

  constructor(props) {
    super(props);
    this._validAuthStates = ["signIn", "signedOut", "signedUp"];
    this.state = { 
      username: null
    }
  }

  restrictSpace(event) {
    if (event.charCode === 32 || event.keyCode === 32) {
      event.preventDefault();
      return false;
    }
  }
  
  handleUsername() {
    this.setState({
      username: localStorage.getItem('username')
    })
  }

  componentDidMount = async () => {
    await this.handleUsername();
  }

  componentWillMount() {
    this.handleUsername();
  }

  showComponent(theme) {

    return (
      <div className="cardMiddle sing-in-content">
        <img
          src={logoMaisSaber}
          alt={settings.title_site}
          className="img-fluid logo"
        />
        <h1 className="text-center">{I18n.get("Sign in to your account")}</h1>
        <form>
          {window.location.search.indexOf('email=') === -1 ? (
            <InputMask
              className="input"
              key="username"
              name="username"
              onChange={this.handleInputChange}
              type="text"
              placeholder={I18n.get("Enter your username")}
              maskChar=""
              mask="99999999999"
            />
          ):(
            <input
              className="input"
              key="username"
              name="username"
              onChange={this.handleInputChange}
              defaultValue={this.state.username ? atob(this.state.username) : ''}
              onKeyPress={event => this.restrictSpace(event)}
              type="email"
              placeholder={I18n.get("Enter your email")}
            />
          )}

          <input
            className="input"
            id="password"
            key="password"
            name="password"
            onChange={this.handleInputChange}
            type="password"
            placeholder={I18n.get("Enter your password")}
          />                          

          <button
            className="btn"
            type="button"
            onClick={(event) => this.signIn(event)}
          >
            {I18n.get('Sign in')}
          </button>
        </form>

        <a
          className="forgot-password"
          onClick={() => super.changeState("forgotPassword")}
        >
          {I18n.get("Forgot Password")}
        </a>

        <img
          src={logoSoma}
          alt={settings.title_site}
          className="img-fluid logo-soma"
        />
      </div>
    );
  }
}